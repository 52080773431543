import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { LeaderboardProps, LeaderboardTableRow } from './Leaderboard';
import { fetchLeaderboard, FetchBoardParameters, FetchLeaderBoardResult } from './leaderboardAPI';

const R = require('ramda')

interface SpaceTuple {
  space: string
  subspace: string
}

export interface LeaderboardSpaceMeta {
  space: string
  subSpaces?: string[]
  firstSubSpace: string
}

export interface LeaderboardState {
  app: string
  space: string
  subspace: string
  selfRank: LeaderboardProps | null
  topRank: LeaderboardProps
  spaceMetas: LeaderboardSpaceMeta[]
  currentSpaceMeta?: LeaderboardSpaceMeta
  status: 'idle' | 'loading' | 'failed'
}

const initialState: LeaderboardState = {
  app: 'retrorally',
  space: '',
  subspace: '',
  selfRank: { table: [] },
  topRank: { table: [] },
  spaceMetas: [],
  currentSpaceMeta: undefined,
  status: 'idle',
};

const mapStringArrayToLeaderboardTableRow = (tablerows: string[], rank: number = 0) => {
  let rankAdjust = 0
  if (rank > tablerows.length) {
    rankAdjust = Math.ceil(rank - tablerows.length / 2) + 1
  }

  return tablerows.map((strItem: string, index) => {
    const splittedItem = strItem.split(": ")
    const result: LeaderboardTableRow = {
      uniqueId: splittedItem[0],
      score: parseInt(splittedItem[1]) / 10,
      rank: index + 1 + rankAdjust
    }
    return result
  })
}
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchBoard = createAsyncThunk(
  'leaderboard/fetchBoard',
  async (params: FetchBoardParameters) => {
    const response = await fetchLeaderboard(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const fetchSpaceMetas = createAsyncThunk(
  'leaderboard/fetchSpaceMetas',
  async (app: string) => {
    const response = await fetchLeaderboard({ app, id: undefined, space: undefined, subspace: undefined });
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchTopBoard: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.topRank = { table: [] };
    },
    fetchSelfBoard: (state) => {
      state.selfRank = { table: [] };
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoard.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchBoard.fulfilled, (state, action) => {
        state.status = 'idle';

        const payload = action.payload as FetchLeaderBoardResult;
        const { space, subspace, toptablerows, selftablerows, rank } = payload
        const topPayloadRows = mapStringArrayToLeaderboardTableRow(toptablerows)
        const topTable: LeaderboardProps = { table: topPayloadRows };

        let selfTable: LeaderboardProps | null = null;
        if (selftablerows) {
          const selfPayloadRows = mapStringArrayToLeaderboardTableRow(selftablerows, rank)
          selfTable = { table: selfPayloadRows };
        }

        state.space = space
        state.subspace = subspace
        state.topRank = topTable
        state.selfRank = selfTable

        state.currentSpaceMeta =
          R.compose(
            R.head(),
            R.filter((spaceMeta: LeaderboardSpaceMeta) => { return state.space === spaceMeta.space })
          )(state.spaceMetas)
      })
      .addCase(fetchSpaceMetas.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchSpaceMetas.fulfilled, (state, action) => {
        state.status = 'idle';
        const payload = action.payload as FetchLeaderBoardResult;
        const tablerows = payload.toptablerows;

        const spaceTuples = R.map((str: string) => {
          const splits = str.split("::")
          const spaceTuple: SpaceTuple = {
            space: splits[2],
            subspace: splits[3],
          }
          return spaceTuple
        }, tablerows)

        const spaces: Set<string> = new Set<string>()
        spaceTuples.forEach((tuple: SpaceTuple) => {
          spaces.add(tuple.space)
        });

        let spaceNames = Array.from(spaces);
        state.spaceMetas = R.map((spaceName: string) => {
          const subSpaces: string[] =
            R.compose(
              R.filter((subSpace: string) => { return subSpace }),
              R.map((tuple: SpaceTuple) => { return tuple.subspace }),
              R.filter((tuple: SpaceTuple) => { return tuple.space === spaceName })
            )(spaceTuples)

          const spaceMeta: LeaderboardSpaceMeta = {
            space: spaceName,
            firstSubSpace: '',
            subSpaces
          }

          if (subSpaces && subSpaces.length > 0) {
            spaceMeta.firstSubSpace = subSpaces[0]
          }

          return spaceMeta

        })(spaceNames)

        state.currentSpaceMeta =
          R.compose(
            R.head(),
            R.filter((spaceMeta: LeaderboardSpaceMeta) => { return state.space === spaceMeta.space })
          )(state.spaceMetas)

      })
  },
});

export const { fetchTopBoard, fetchSelfBoard } = leaderboardSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTopBoard = (state: RootState) => state.leaderboard.topRank;
export const selectSelfBoard = (state: RootState) => state.leaderboard.selfRank;
export const selectSpace = (state: RootState) => state.leaderboard.space;
export const selectSubSpace = (state: RootState) => state.leaderboard.subspace;
export const selectSpaceMetas = (state: RootState) => state.leaderboard.spaceMetas;
export const selectCurrentSpaceMeta = (state: RootState) => state.leaderboard.currentSpaceMeta;


export default leaderboardSlice.reducer;
