import React, { useEffect } from 'react';
import { Table, Button } from 'react-bootstrap'
import Select from 'react-select'
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  fetchBoard,
  fetchSpaceMetas,
  selectSpace,
  selectSubSpace,
  selectSpaceMetas,
  selectCurrentSpaceMeta,
  selectTopBoard,
  selectSelfBoard
} from './LeaderboardSlice'
import styles from '../counter/Counter.module.css';
import configMap from './configMap.json';


const R = require('ramda')

export interface LeaderboardTableRow {
  uniqueId: string
  rank: number
  score: number
}


export interface LeaderboardProps {
  table: Array<LeaderboardTableRow>
}

const stringFormat = (template: string, ...args: any[]) => {
  return template.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined'
      ? args[number]
      : match
      ;
  });
};

export function Leaderboard() {

  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  let { app, space, subspace } = useParams();
  const stateSpace = useAppSelector(selectSpace);
  const stateSubSpace = useAppSelector(selectSubSpace);
  const stateSpaceMetas = useAppSelector(selectSpaceMetas);
  const currentSpaceMeta = useAppSelector(selectCurrentSpaceMeta);
  const navigate = useNavigate()
  const appConfig = R.compose(
    R.head(),
    R.filter((config: any) => config.app === app)
  )(configMap)
  const spaceConfig =
    R.compose(
      R.head(),
      R.filter((spaceSetting: any) => spaceSetting.space === space)
    )(appConfig.spaceSettings)

  // Similar to componentDidMount and componentDidUpdate:  
  useEffect(() => {
    if (stateSpaceMetas === undefined || stateSpaceMetas.length === 0) {
      const appStr = app === null ? "" : app as string
      dispatch(fetchSpaceMetas(appStr))
    }
    // Update the document title using the browser API    
    if (stateSpace !== space || stateSubSpace !== subspace) {
      let id = searchParams.get("id")

      if (id === null) {
        id = ''
      }
      dispatch(fetchBoard({ id, app, space, subspace }))
    }
  });


  const topBoard = useAppSelector(selectTopBoard)

  //check if selfboard is needed
  const selfBoard = useAppSelector(selectSelfBoard)
  let selfBoardDiv = <div></div>
  if (selfBoard) {
    selfBoardDiv = <React.Fragment>
      <tr>
        <td> </td>
        <td>. </td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td>. </td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td>. </td>
        <td> </td>
      </tr>

      {selfBoard.table.map((item, index) => (
        <tr className={item.uniqueId === id ? styles.rowid : ""} tabIndex={item.uniqueId === id ? 0 : -1}>
          <td key={index}>{item.rank}</td>
          <td key={index}>{item.uniqueId}</td>
          <td key={index}>{item.score}</td>
        </tr>
      ))}


    </React.Fragment >
  }

  //check if select is needed
  let selectDiv = <div></div>

  if (subspace) {
    const subSpaceLabel = stringFormat(spaceConfig.subSpaceDisplayName ? spaceConfig.subSpaceDisplayName : '', subspace)
    selectDiv = <div>
      <Select
        options={currentSpaceMeta?.subSpaces?.map(subspace => {
          return {
            option: subspace,
            label: stringFormat(spaceConfig.subSpaceDisplayName ? spaceConfig.subSpaceDisplayName : '', subspace)
          }
        })}
        value={{ option: subspace, label: subSpaceLabel }}
        onChange={(option) => {
          navigate(`../${app}/${space}/${option?.option}${id ? '?id=' + id : ''}`)
        }}
      />
    </div>
  }

  return (
    <React.Fragment>
      <div className={styles.leaderboard} style={{
        backgroundImage: `url("${spaceConfig.backgroundFilePath}")`,
      }} />
      <div className={styles.leaderboardTitleGroup} style={{
        backgroundColor: `${spaceConfig.tableBackgroundColor}`
      }}>
        <div className={styles.leaderboardGameName}> {appConfig.displayName} </div>

        <div className={styles.leaderboardTitle}>Leaderboard</div>
      </div>
      <div className={styles.row}>
        {
          stateSpaceMetas.map(spaceMeta => (
            <Button
              className={styles.button}
              onClick={() => {
                navigate(`../${app}/${spaceMeta.space}/${spaceMeta.firstSubSpace}${id ? '?id=' + id : ''}`, { replace: true })
              }}
            >
              {spaceMeta.space}
            </Button>
          ))
        }
      </div>
      {selectDiv}
      <Table style={{
        backgroundColor: `${spaceConfig.tableBackgroundColor}`
      }} responsive>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Serial Number</th>
            <th>Mileage</th>
          </tr>
        </thead>
        <tbody>
          {topBoard.table.map((item, index) => (
            <tr className={item.uniqueId === id ? styles.rowid : ""} tabIndex={item.uniqueId === id ? 0 : -1}>
              <td key={index}>{item.rank}</td>
              <td key={index}>{item.uniqueId}</td>
              <td key={index}>{item.score}</td>
            </tr>
          ))}
          {selfBoardDiv}
        </tbody>
      </Table>

    </React.Fragment >
  )
}

export default Leaderboard
