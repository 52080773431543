import axios, { AxiosRequestConfig } from "axios";
import { LeaderboardTableRow } from './Leaderboard';

export interface FetchBoardParameters {
  app: string | undefined;
  space: string | undefined;
  subspace: string | undefined;
  id: string | undefined;
}
export interface FetchLeaderBoardResult {
  app: string;
  space: string;
  subspace: string;
  rank: number;
  toptablerows: string[]
  selftablerows: string[] | undefined;
}

interface GoblinLeaderboardServiceGetResponse {
  m_rank: number
  m_topList: string[]
  m_selfList: string[]
}

export function fetchLeaderboard(params: FetchBoardParameters) {
  return new Promise((resolve) => {

    const baseUrl = process.env.REACT_APP_BUILD_ENV === 'develop' ? 'https://leaderdev.goblin-software.com/leaderboard' : 'https://leaderboard.goblin-software.com/leaderboard';
    const { app, space, subspace, id } = params;
    let url = `${baseUrl}/${app}`
    if (space !== undefined) {
      url = `${url}/${space}`
    }
    if (subspace !== undefined) {
      url = `${url}/${subspace}`
    }
    if (id != null && !id.match(/^ *$/)) {
      url = `${url}?id=${id}`
    }
    const options: AxiosRequestConfig = {
      url,
      method: "GET",
    };

    axios(options).then(serverResult => {
      if (!space) {
        let result = serverResult.data
        resolve({ space, subspace, toptablerows: result })
      }
      else {
        let result: GoblinLeaderboardServiceGetResponse = serverResult.data;
        resolve({ space, subspace, rank: result.m_rank, toptablerows: result.m_topList, selftablerows: result.m_selfList })
      }
    }).catch(err => {
      let tablerows: Array<LeaderboardTableRow> = []
      console.log(`error when getting board ${err}`)
      resolve({ space, toptablerows: tablerows })
    })

  });
}
