import React from 'react';
import { Leaderboard } from './features/leaderboard/Leaderboard';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

function App() {

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/:app/:space/:subspace" element={<Leaderboard />} />
            <Route path="/:app/:space" element={<Leaderboard />} />
            <Route path="/:app" element={<Leaderboard />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
